@font-face {
  font-family: inter-extra-light;
  src: url("../src/fonts/inter/Inter-Light.ttf");
}
@font-face {
  font-family: oxygen-regular;
  src: url("../src/fonts/Oxygen/Oxygen-Regular.ttf");
}
@font-face {
  font-family: metropolis-regular;
  src: url("../src/fonts/metropolis/Metropolis-Regular.otf");
}
@font-face {
  font-family: nephlim-regular;
  src: url("../src/fonts/Nelphim-Font/Nephilm.otf");
}
html {
  scroll-behavior: smooth;
}
h1,h2,h3,h4,h5,h6{
  color: #1d4028;
  font-family: nephlim-regular;
}
.landing-container-fluid{
  background-color: red;
}
body{
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  font-family: metropolis-regular;
  color: #666;
  background-image: url("./images/patterns/CMYK_YOLO\ PATTERN\ 4\ WHITE.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.container-fluid{
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.carousel-images{
  height: 750px;
}
.nav-logo{
  width: 100px;
}
.navbar-custom {
  /* overflow: hidden ; */
  height: 90px;
  width: 100%;
  position: fixed;
 background-color: #262626;
  z-index: 9;
}
.landing-booking-div{
  width: 100%;
  min-height: 200px;
  background-color: #4d4d4d;
  border-radius: 3px;
  margin-top: -100px;
  background-color: white;
  padding: 20px;
}
.form-control{
height:50px;
border-radius: 2px;
}
.form-select{
  height:50px;
  }
.landing-booking{
  margin-top: 30px;
}
.landing-page-image{
  width: 100%;
  min-height: 700px;
  background-image: url("../src/images/imageone.jpg");
  background-size: cover;
  background-position:bottom;
}
.landing-page-inner{
  width: 100%;
  min-height: 700px;
  background-color:#4d4d4d;
  background-color: rgba(0,0,0,.7);
  padding: 1px;
}
.landing-page-text{
  margin-top: 200px;
  text-align: center;
  color: white;
}
.landing-page-inner button{
  margin: auto;
  display: block;
  color: white;
  background-color: #1d4028;
  border: none;
  height: 50px;
  width: 200px;
  border-radius: 2px;
}
.form-control:focus{
  box-shadow: none !important;
  border:1px solid #1d4028;
  }

.book-button{
  margin: auto;
  display: block;
  color: white;
  background-color: #1d4028;
  border: none;
  height: 50px;
  width: 200px;
  border-radius: 2px;
}
.small-screen-summary p{
  margin-top: 18px;
  color: white;
  cursor: pointer;
  /* font-size: 20px; */
}
.small-screen-summary{
  width: 90%;
  height:55px;
  position: fixed;
  background-color:#325e40;
  bottom:15px;
  border-radius: 10px;
  /* left:0; */
  margin-left:20px;
  margin-right:20px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
#small-screen-order{
  border: 2px purple !important;
}
.confirmation-form{
  width: 100%;
  min-height: 400px;
  padding: 20px;
  background-color:white;
  margin-top: 100px;
}
.attendance-actions{
  height: 40px;
  width: 100px;
  border:none;
  color: white;
}
.spinner-border{
  width: 3rem; 
  height: 3rem;
  margin: auto;
}
.accordion-nav{
  background-color: #1d4028 !important;
  color: white;
}
.accordion-nav-header{
  background-color: #304c32 !important;
}
.accordion-nav-button{
  background-color: #1d4028;
  padding: 0;
  color: white;
}
.event-page-space{
  background-image: url("../src/images/event-center.jpg");
  width: 100%;
  height: 400px;
  background-position: center;
}
.event-page-space2{
  background-image: url("../src/images/imageone.jpg");
  width: 100%;
  height: 550px;
  background-position: center;
  background-size: cover;
}
#add-access-levels{
  cursor: pointer;
}
#add-access-levels:hover{
  font-weight: bold;
}
.accordion-nav-button:not(.collapsed){
  background-color: #1d4028;
  color: white;
}
.accordion-nav-button:focus{
  box-shadow: none;
}
.menu-price{
  color: white;
  background-color: red;
  padding:5px;
  border-radius:20px;
  font-size: 12px;
}
.menu-image-customer{
  width: 100%;
  object-fit: cover;
  height: 185px;
  border-radius: 10px;
}
.menu-image img{
  width: 100%;
  object-fit: cover;
  height: 185px;
  border-radius: 10px;
}
.categories-customer{
  padding: 10px;
  min-width: 100px;
  font-size: 12px;
  border: 1px solid grey;
  margin-left: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  margin-bottom: 10px;
}
.order-summary{
  border: 1px solid #1d4028;
  margin-top: 30px;
  min-height: 100px;
  border-radius: 5px;
  padding: 20px;
}
.order-summary-items{
  font-size: 18px;
}
.menu-box{
  margin-bottom: 20px;
  cursor: pointer;
  transition: 1s;
  border: 3px !important;
}
.categories-customer:hover{
  background-color: #843df5;
  color: white;
  font-weight: bold;
}
.add-to-order{
  background-color: #843df5;
  padding:5px;
  border-radius:20px;
  font-size: 12px;
  color: white;
  font-weight: bold;
}
.quantity-menu i{
  font-size:35px;
  cursor: pointer;
}
.quantity-menu i:hover{
  color: #1d4028;
  font-weight: bold;
}
.add-to-order:hover{
  border: 2px solid black;
}
.category-display{
  background-color:#800db5;
  color: white;
  padding:5px;
  font-size: 12px;
  border-radius:20px;
}
.small-button{
  display: block;
  color: white;
  background-color: #1d4028;
  border: none;
  height: 43px;
  width: 120px;
  font-size: 14px;
  border-radius: 2px;
}
.landing-page-inner-heading1{
  font-size: 60px;
  color: white;
  font-weight: bold;
  font-family: nephlim-regular;
}
.landing-page-inner-heading2{
  font-size:25px;
  color: white;
}
.navbar-custom a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  margin-top:15px;
  text-decoration: none;
}

.dropdown-custom {
  float: left;
  z-index: 10;
  overflow: hidden;
}

.dropdown-custom .dropbtn-custom {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  margin-top: 15px;
}


.dropdown-content-custom {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content-custom a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.admin-menu{
  margin-bottom: 25px;
}
.dropdown-content-custom a:hover {
  background-color: #ddd;
}

.dropdown-custom:hover .dropdown-content-custom {
  display: block;
}
.second-nav-options{
  width: 200px;
  height: 45px;
  background-color: #a4ab1d;
  margin-top: 25px;
  line-height: 15px;
  margin-left: 40px;
}
.about-div h4{
  text-align: center;
  font-weight: 700;
}
.about-div p{
  font-size: 17.5px;
}
h4{
  text-align: center;
  font-weight: 700;
}
.about-us-images0{
  width: 100%;
  min-height: 200px;
  margin-bottom: 50px;
  background-image: url("../src/images/image2.jpg");
  border-radius: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-us-images1{
  width: 100%;
  min-height: 200px;
  margin-bottom: 5px;
  background-image: url("../src/images/image5.jpg");
  border-radius: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
/* .about-us-images img{
  width: 100%;
  height:222px;
  border-radius: 30px;
} */
.about-us-images2{
  width: 100%;
  height: 450px;
  border-radius: 30px;
  background-image: url("../src/images/image1.jpg");
  background-position:left;
}
.page-logo img{
  width: 200px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.page-logo{
  background-color:#1d4028;
  padding: 30px;
  margin-top: 100px;
}
.carousel-caption p{
  background-color: #1d4028;
  margin-top: -400px;
}
.room-section{
  width: 100%;
  height: 800px;
  padding: 10px;
}
.rooms-images{

}
.best-experience-icons h4{  
  text-align: center;
}
.best-experience-icons{
  width: 100%;
}
.landing-icons{
  font-size: 45px;
  text-align: center;
  color: #a4ab1d;
}
.landing-icons-container{
  margin-top: 80px;
  text-align: center;
  font-size: 13px;
  padding: 20px;
  width: 200px;
  height: 200px;
}
.landing-icons-container span{
  font-weight: bold;
}
.big-vertical-sections{
  height:350px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.chalet-boxes{
  width: 100%;
  height: 250px;
  margin-top: 50px;
  background-image: url('./images/room1.jpg');
  background-size: cover;
  background-position: center;
  transition: 1s;
}
.chalet-boxes:hover{
  -ms-transform: scale(1.1) !important; /* IE 9 */
  -webkit-transform: scale(1.1) !important; /* Safari 3-8 */
  transform: scale(1.1) !important; 
}
.inner-chalet-boxes{
  height: 250px;
  width: 100%;
  padding: 1px;
  color: white;
  transition: 1s;
  cursor: pointer;
  background-color: rgba(0,0,0,.3);
}
.inner-chalet-boxes p{
  text-align: center;
  margin-top: 25%;
}
.inner-chalet-boxes:hover{
  background-color: rgba(0,0,0,.6);
}
.chalet-boxes-reservations{
  width: 100%;
  min-height: 250px;
}
.images-event-scroll{
  width: 100%;
  min-height: 350px;
}
.services-div{
  font-size: 40px;
  padding: 20px;
  margin-top: 20px;
  color: #a4ab1d;
}
.services-heading{
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  color: #1d4028;
}
.services-text{
  font-size: 14px;
  text-align: left;
  color: #4d4d4d;
}
.promotional-video-div{
  min-height: 600px;
  width: 100%;
  background-image: url("../src/images/court.jpg");
  background-size: cover;
  background-attachment: fixed;
}
.promotional-video-div-inner{
  width: 100%;
  min-height: 600px;
  background-color: rgba(0,0,0,.8);
}
.promotional-vid-text{
    color: white;
    margin-top: 200px;
    padding: 20px;
}
.promotional-vid-text h1{
  font-weight: bold;
}
.promotional-video{
  padding: 30px;
}
.footer{
  min-height:300px;
  width: 100%;
  background-color: #1d4028;
  color: white;
}
.footer-div{
  margin-top: 50px;
  color: white;
  padding: 0;
  min-height: 300px;
}
.footer-div h3{
  color: white;
}
.footer-div p{  
  margin-top: 50px;
}

.footer-div img{
  width: 200px;
  margin-top: 30px;
}
.footer-div ul{
  list-style-type: none;
  padding: 0;
  margin-right: auto;
}

.footer-div li{
  margin-top: 10px;
}
.booking-div{
  width:90%;
  min-height: 580px;
  background-color: white;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.confirm-icons{
  cursor: pointer;
}
.confirm-icons:hover{
  font-weight: bold;
}
.number-counters{
  font-size: 25px;
  margin-top: 10px;
  cursor: pointer;
}
.occupants-value{
  margin-left:15px;
  margin-right: 15px;
}
.contact-details-booking{
  margin-top: 0px;
}
.side-nav{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1d4028;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
  /* padding: 20px; */
  z-index: 10;
}
.page-header{
  margin-top: 90px;
  height: 300px;
  width: 100%;
  background-image: url("../src/images/beach.jpg");
  background-size: cover;
  background-position: center;
}
.page-inner-header{
  width: 100%;
  background-color:rgba(0,0,0,.6);
  height: 300px;
  padding: 1px;
}
.activity-card{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.activities-card-images{
  height: 280px;
  width: 100%;
  transition: transform 1s;
}
.activities-card-images:hover{
  -ms-transform: scale(1.1) !important; /* IE 9 */
  -webkit-transform: scale(1.1) !important; /* Safari 3-8 */
  transform: scale(1.1) !important; 
}
.page-heading{
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  color: white;
  font-weight: bold;
  margin-top: 100px;
}
.activity-sections{
  background-color: white;
  height: 220px;
  width: 100%;
  border-radius: 2px;
}
.activity-side-div{
  width: 100%;
  height: 100%;
  background-color:#f3f3f3;
  padding: 20px;
}
.landing-side-nav-options{
  margin-top: 30px;
  cursor: pointer;
  font-size: 16px;
}

.landing-side-nav-options :hover{
  font-weight: bold;
}
.chalet-circle{
  width: 100%;
  height: 400px;
  background-size: cover;
  margin-top: 30px;
  color: white;
  cursor: pointer;
  background-position: center;
}
.main-chalet-inner{
  transition: 1s;
  height: 100%;
  width: 100%;
}
.booking-page-images{
  height: 220px;
  width: 100%;
}
.event-page-images{
  width: 90%;
  min-height: 500px;
}
.restaurant-bar-div{
  margin-top: 150px;
  padding: 20px;
  min-height: 590px;
  background-color: white;
  font-size: 17px;
}
.contact-us-right{
  height:680px;
  width: 90%;
  margin-top: 120px;
  background-color:white;
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.contact-us-right-inner{
  margin-top:50px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.contact-container{
  width: 90%;
  min-height: 350px;
  margin: auto;
  margin-top: 120px;
}
.contact-icons{
  color: #1d4028;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
}
.contact-360{
  height:250px;
  border-radius:10px;
  width:100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 3px;
  transition: 1s;
}
.contact-360:hover{
  background-color: #f3f3f3;
}
.contact-details{
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
}
/* .contact-us-input{
  width: 90%;
} */
/* .contact-us-right input{
  margin-top: 50px;
} */
.footer-links{
  text-decoration: none;
  color: #f3f3f3;
}
/* Admin Styling properties and values starts here */
.admin-landing-page{
  background-color: #1d4028;
  min-height: 100vh;
}
.admin-login-div{
  width: 90%;
  height: 250px;
  background-color:#f3f3f3;
  border-radius: 2px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}
#admin-landing-logo{
  width: 200px;
  margin-top:200px;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.admin-side-nav{
  height: 100vh;
  width: 100%;
  background-color: #304c32;
  padding: 1px;
  color: white;
}
.admin-side-options{
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.admin-side-options:hover{
  font-weight: bold;
  color: gold;
}
.admin-content{
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}
.admin-views-container{
    height:100%;
    width: 100%;
    padding: 10px;
    background-color: #f3f3f3;
    overflow-x: hidden;
}
.form-control-admin{
  height:36px;
  border-radius: 2px;
  }
#add-chalet-form-container{
  padding: 20px;
}
.right-side-admin{
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  /* background-color: #e5e0e0; */
  padding: 10px;
  /* border-radius: 1px solid red; */
}
.chalet-icons{
  cursor: pointer;
}
.chalet-cards{
  margin: auto;
  margin-top: 20px;
  width: 95%;
}
.card{
  margin: auto;
  border-radius: 0px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.add-challet-icon{
  font-size: 20px;
  margin-left: 20px;
  margin-top: 50px;
  cursor: pointer;
}
.add-challet-icon:hover{
  color: #1d4028;
}
.inner-admin-container{
  width: 90%;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.load-more-button{
  cursor: pointer;
}
#floating-menu-icon{
  width: 80px;
  height: 80px;
  background-color: #1d4028;
  position: fixed;
  bottom: 10px;
  left: 100;
  right:0;
  margin-left: 20px;
  text-align: center;
  padding: 15px;
  border-radius: 50%;
  font-size: 35px;
  color: white;
}
/* #floating-icon-container{
  width: 80px;
  height: 80px;
  position: fixed;
  bottom: 0;
  right: 0;
  border: 1px solid red;
  padding-right: 20px;
} */
.admin-side-nav-responsive{
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  color: white;
  background-color:#1d4028; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 30px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.admin-boxes{
  min-width: 95%;
  min-height: 150px;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
  font-weight:900;
  margin-top: 20px !important;
  margin: auto;
  padding: 10px;
}
#admin-side-image{
  background-image: url("../src/images/image2.jpg");
  height: 100vh;
  width: 95%;
  background-size: cover;
  background-position: center right;
}
.add-edible-box{
  width:100%;
  min-height: 350px;
  background-color: white;
  margin-top:30px;
  padding: 30px;
}
.admin-tables-box{
  overflow: auto;
}
.membership-plans{
  width: 100%;
  min-height:700px;
  background-color:#1d4028;
  padding: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.membership-plans h4{
  color: white;
}
.visit-div{
  background-color: white;
  min-height:1000px;
  width: 100%;
  padding: 20px;
  background-image: url('../src/images/visitimage-plane.png');
  background-size: cover;
  background-position: center;
  border-radius:10px;
}
.event-div-background{
  background-color: white;
  min-height:700px;
  width: 50%;
  padding: 20px;
  background-image: url('../src/images/val-poster.jpg');
  background-size: cover;
  background-position: center;
  border-radius:10px;
}
.current-event-form-container{
  width: 90%;
  height: 550px;
  padding: 10px;
  margin: auto;
  background-color: white;
}
.visit-option-box{
  width:165px;
  margin: auto;
  height: 200px;
  background-color:white;
  /* margin-top: 300px; */
  border-radius:10px;
  cursor: pointer;
  transition: 1s;
  padding-left: 2px;
  padding-right: 2px;
}
.package-detail{
  color: #006266;
  font-size:1.5rem;
  text-align: center;
  margin-top: 20px;
}
.visit-option-box:hover{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.flex-container-membership{
  display: flex;
}
.membership-benefit-title{
  font-weight: bold;
  color: #a4ab1d;
}
.sub-visit-options{
  width:60%;
  margin: auto;
  cursor: pointer;
  height: 100px;
  padding-right:5px;
  padding-top: 10px;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  background-color: white;
}
.sub-visit-options p{
  margin-top: 20px !important;
}
#membership-nav{
  width: 100%;
  height: 55px;
  background-color: #1d4028;
}
.member-actions{
  cursor: pointer;
  margin-bottom: 20px;
}
.member-actions:hover{
  font-weight: bold;
}
.membership-side-nav{
  width: 100%;
  padding: 1px;
  height: 100vh;
  background-color: #1d4028;
  color: white;
  
}
.membership-side-nav-mobile{
  width: 0;
  padding: 1px;
  height: 100vh;
  background-color: #1d4028;
  color: white;
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
}
.membership-box{
  width:100%;
  min-height:100vh;
  background-color: white;
  margin: auto;
}
.profile-items{
  height: 100px;
  width: 90%;
  border-radius: 3px;
  background-color: #f3f3f3;
  margin: auto;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #1d4028;
}
.m-side-nav-options{
  cursor: pointer;
  font-size: 18px;
}
.m-side-nav-options:hover{
  font-weight: bold;
}
.m-sourounding{
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.m-sourounding:hover{
  background-color: #44664f;
}
.sub-member-action{
  cursor: pointer;
}
.sub-member-action:hover{
  font-weight: bolder;
}
.membership-home{
  background-image: url("../src/images/image2.jpg");
  height:95vh;
  margin-top:20px !important;
  width: 95%;
  background-size: cover;
  background-position: center right;
}
.home-page-info-box{
  width: 90%;
  min-height: 60px;
  border-radius: 3px;
  background-color: #44664f;
  margin: auto;
  margin-top: 20px;
  color: white;
  padding: 5px;
  padding-left: 10px;
}
.home-page-info-box span{
  line-height: 56px;
}
.membership-hp{
  background: rgb(29,64,40);
  background: linear-gradient(90deg, rgba(29,64,40,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}
.profilePicture{
  height: 200px;
  width: 200px;
  border: 5px solid white;
  margin:25px auto;
  border-radius: 50%;
}
.profile-pic-background{
  background-image: url("../src/images/image5.jpg");
  height:250px;
  margin-top:20px !important;
  width:98%;
  padding: 1px;
  margin: auto;
  background-size: cover;
  background-position: center right;
}
.socials{
  margin-left:10px;
  margin-right:10px;
}
.social-links{
  text-decoration:none;
  color:white;
}
.admin-booking-summary{
  width: 100%;
  min-height:20px;
  border-radius: 3px;
  padding-top: 5px;
}
/* .membership-top{
  margin-top: 100px;
} */
/* Admin styling properties and values end here */
#visit-main-div{
  width:100%;
  margin:auto;
  margin-top: 60px;
  padding: 5px;
}

@media only screen and (max-width: 768px) {
  .navbar-custom{
    height: 70px;
  }
  .nav-logo{
    margin-top: -10px;
  }
  .nav-ham{
    margin-top: -20px !important;
  }
    #visit-main-div{
      width: 100%;
    }
    .package-detail{
      font-size: 17px;
    }
    .package-detail-price{
      font-size: 30px;
      text-align: center;
    }
    /* .visit-div{
      background-color: white;
    } */
    .visit-option-box{
      width:130px;
    }
    .sub-visit-options{
      height: 70px;
    }
    .inner-option-container{
      margin-top:50px !important;
    }
    #nav-bar-inner a{
      font-size: 13px;
    }
}

@media only screen and (min-width: 768px) {
  .package-detail{
    font-size: 17px;
  }
  .package-detail-price{
    font-size: 30px;
    text-align: center;
  }

}

@media only screen and (max-width: 768px) {

  .visit-div{
    background-image: url('../src/images/visitimage-plane.png');
    background-size: cover;
    background-position: center;
    border-radius:10px;
    min-height: 500px;
  }
  .visit-init{
    margin-top: 50px !important;
  }
}

/* @media only screen and (max-width: 575px) {

  .current-event-form-container{
    background-image: url('../src/images/PALMWINE\ PARADISE.png');
    background-size: cover;
    background-position: center;
    border-radius:10px;
  }

} */